import React from 'react'
import Helmet from 'react-helmet'

const schemaOrgJSONLD = {
  "@context": "http://www.schema.org",
	"@type": ["Organization", "LocalBusiness"],
    "name": "Pawsome Dogs Training and Behavior",
    "url": "https://www.pawsomedogs.net",
    "sameAs": ["https://pawsomedogs.net","https://www.facebook.com/PawsomeDogs","https://www.instagram.com/pawsomedogstraining/","https://www.yelp.com/biz/pawsome-dogs-chicago"],
    "logo": "https://www.pawsomedogs.net/logo.jpg",
    "image": "https://www.pawsomedogs.net/logo.jpg",
    "description": "Pawsome Dogs offers reward-based dog training in Chicago, transforming your dog into a well-behaved companion. We customize our training to fit every dog's needs. Specializing in training and behavior modification at your home where training really counts.",
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Chicago",
      "addressRegion": "IL",
      "postalCode": "60618",
      "addressCountry": "United States"
    },
    "priceRange" : "$100 - $500",
    "email": "melissa@pawsomedogs.net",
    "telephone": "+1 (773) 818-7204",
    "openingHours": "Mo, Tu, We, Th, Fr 09:00-21:00 Sa, Su 10:00-17:00",
    "contactPoint": {
      "@type": "ContactPoint",
      "contactType": "sales",
      "email": "melissa@pawsomedogs.net",
      "telephone": "+1 (773) 818-7204",
      "areaServed": ["Chicago","US"],
      "availableLanguage": "English"
    }
  }

const SEO = ({ description, title = 'Pawsome Dogs | Chicago Dog Training' }) =>
  <Helmet>
    <title>{title}</title>
    <meta name='description' content={description} />
    <script type='application/ld+json'>{JSON.stringify(schemaOrgJSONLD)}</script>
  </Helmet>

export default SEO