import React from 'react'
import PropTypes from 'prop-types'

import './Container.scss'

const Container = ({ children, className, ...others }) => {
  return (
    <div className={`Container ${className}`} {...others}>
      {children}
    </div>
  )
}

Container.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  className: PropTypes.string,
}

export default Container
