import React from 'react'

import Link from './Link'

import fbImage from '../assets/img/icons/facebook.svg'
import instaImage from '../assets/img/icons/instagram.svg'
import yelpImage from '../assets/img/icons/yelp.svg'

import './Footer.scss'

const Footer = () => (
  <footer className='Footer'>

    <div className='Footer--social'>
      <a href='https://www.facebook.com/PawsomeDogs/' target='_blank' rel='noopener noreferrer'><img src={fbImage} alt='facebook' /></a>
      <a href='https://www.instagram.com/pawsomedogstraining/' target='_blank' rel='noopener noreferrer'><img src={instaImage} alt='instagram' /></a>
      <a href='https://www.yelp.com/biz/pawsome-dogs-chicago' target='_blank' rel='noopener noreferrer'><img src={yelpImage} alt='yelp' /></a>
    </div>

    <div className='Footer--contact'>
      <a href='mailto:melissa@pawsomedogs.net' target='_top'>melissa@pawsomedogs.net</a>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <a href='tel:773-818-7204' target='_top'>773-818-7204</a>
    </div>

    <div className='Footer--links'>
      <Link to='/Services'>Services</Link>
      <Link to='/Me'>About Me</Link>
      <Link to='/Contact'>Contact</Link>
      <Link to='/Testimonials'>Testimonials</Link>
    </div>

    <div className='Footer--copyright'>
      &copy; 2024 Pawsome Dogs. All Rights Reserved.
    </div>

  </footer>
)

export default Footer
