import React from 'react'

import SEO from './SEO'
import { Header, MobileNav, TopHeader } from './Header'
import Footer from './Footer'

import './Layout.scss'

const Layout = ({ children, title, description, location }) =>
  <div className='App'>
    <SEO title={title} description={description} />
    <TopHeader location={location} />
    <Header location={location} />
    {children}
    <Footer location={location} />
    <MobileNav location={location} />
  </div>

export default Layout
