import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'gatsby'

import './Link.scss'

const Link = ({ children, className, cta, red, ...others }) => {
  if (cta) className = `${className} button cta`
  if (red) className = `${className} red`
  return (
    <RouterLink className={`Link ${className}`} {...others}>
      {children}
    </RouterLink>
  )
}

Link.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
  cta: PropTypes.bool,
  red: PropTypes.bool,
}

export default Link
