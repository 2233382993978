import React from 'react'
import 'hamburgers/dist/hamburgers.min.css'

import Link from '../components/Link'
import Container from '../components/Container'

import fbImage from '../assets/img/icons/facebook.svg'
import instaImage from '../assets/img/icons/instagram.svg'
import yelpImage from '../assets/img/icons/yelp.svg'
import fbImageWhite from '../assets/img/icons/facebook-white.svg'
import instaImageWhite from '../assets/img/icons/instagram-white.svg'
import yelpImageWhite from '../assets/img/icons/yelp-white.svg'
import logo from '../assets/img/pawsome-dogs-training.svg'
import phoneImage from '../assets/img/icons/phone-white.svg'
import emailImage from '../assets/img/icons/email-white.svg'

import './Header.scss'

const TopHeader = () =>
  <div className='Header-top'>
    <Container className='Header-top--container'>
      <a href='mailto:melissa@pawsomedogs.net' target='_top'><img src={emailImage} alt='email' /></a>
      <a href='mailto:melissa@pawsomedogs.net' target='_top'>melissa@pawsomedogs.net</a>
      <a href='tel:773-818-7204' target='_top'><img src={phoneImage} alt='phone' /></a>
      <a href='tel:773-818-7204' target='_top'>773-818-7204</a>
      <a href='https://www.facebook.com/PawsomeDogs/' target='_blank' rel='noopener noreferrer'><img src={fbImageWhite} alt='facebook' /></a>
      <a href='https://www.instagram.com/pawsomedogstraining/' target='_blank' rel='noopener noreferrer'><img src={instaImageWhite} alt='instagram' /></a>
      <a href='https://www.yelp.com/biz/pawsome-dogs-chicago' target='_blank' rel='noopener noreferrer'><img src={yelpImageWhite} alt='yelp' /></a>
    </Container>
  </div>

class Header1 extends React.Component {

  _linkClass = to => this.props.location.pathname === to ? 'active' : ''

  render () {

    return (
      <header className='Header'>

        <Link to='/' className='Header--logo'><img src={logo} alt='Pawsome Dogs Training & Behavior' /></Link>

        <nav className='Header--nav' id='Header--nav'>
          <Link to='/' className={this._linkClass('/')}>Home</Link>
          <Link to='/Services' className={this._linkClass('/Services')}>Services</Link>
          <Link to='/Me' className={this._linkClass('/Me')}>About Me</Link>
          <Link to='/Contact' className={this._linkClass('/Contact')}>Contact</Link>
          <Link to='/Testimonials' className={this._linkClass('/Testimonials')}>Testimonials</Link>
        </nav>

      </header>
    )

  }

}

class MobileNav1 extends React.Component {

  _linkClass = to => this.props.location.pathname === to ? 'active' : ''

  _toggle = () => {
    this.nav.classList.toggle('show')
    this.toggle.classList.toggle('is-active')
  }

  render () {

    return (
      <div className='Header-mobile'>

        <nav className='Header-mobile--nav' ref={ref => this.nav = ref}>
          <Link onClick={this._toggle} to='/' className={this._linkClass('/')}>Home</Link>
          <Link onClick={this._toggle} to='/Services' className={this._linkClass('/Services')}>Services</Link>
          <Link onClick={this._toggle} to='/Me' className={this._linkClass('/Me')}>About Me</Link>
          <Link onClick={this._toggle} to='/Contact' className={this._linkClass('/Contact')}>Contact</Link>
          <Link onClick={this._toggle} to='/Testimonials' className={this._linkClass('/Testimonials')}>Testimonials</Link>

          <div className='Header-mobile--social'>
            <a href='https://www.facebook.com/PawsomeDogs/' target='_blank' rel='noopener noreferrer'><img src={fbImage} alt='facebook' /></a>
            <a href='https://www.instagram.com/pawsomedogstraining/' target='_blank' rel='noopener noreferrer'><img src={instaImage} alt='instagram' /></a>
            <a href='https://www.yelp.com/biz/pawsome-dogs-chicago' target='_blank' rel='noopener noreferrer'><img src={yelpImage} alt='yelp' /></a>
          </div>
        </nav>

        <button onClick={this._toggle} ref={ref => this.toggle = ref} className='hamburger hamburger--squeeze'>
          <span className='hamburger-box'>
            <span className='hamburger-inner'></span>
          </span>
        </button>

      </div>
    )

  }

}

const Header = Header1
const MobileNav = MobileNav1

export { Header, MobileNav, TopHeader }
